<template>
  <v-main class="content">
    <router-view class="router-view" />

    <dashboard-core-footer class="footer" />
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    DashboardCoreFooter: () => import("./Footer"),
  },
};
</script>

<style>
/* router-view parent */
.content > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.router-view {
  flex: 1;
}

.footer {
  margin-top: auto;
}
</style>
